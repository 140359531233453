import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c1414ff"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["SmoothStepEdge"], {
      class: "transition-edge",
      id: $props.id,
      path: $setup.d[0],
      markerEnd: $props.markerEnd
    }, null, 8 /* PROPS */, ["id", "path", "markerEnd"]),
    ($props.markerEnd == 'url(#)')
      ? (_openBlock(), _createBlock($setup["EdgeLabelRenderer"], { key: 0 }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              onClick: _cache[0] || (_cache[0] = _withModifiers($event => ($setup.selectAction(true)), ["stop"])),
              style: _normalizeStyle({
				transform: `translate(-50%, -50%) translate(${$setup.d[1]}px, ${$setup.d[2]}px)`,
				borderColor: $props.selected ? 'var(--primary)' : 'var(--gray-600)',
				borderWidth: $props.selected ? '1.5px' : '1px'
			}),
              class: "access nodrag nopan"
            }, [
              _createElementVNode("span", {
                class: "mr-1",
                innerHTML: _ctx.frappe.utils.icon('users', 'sm')
              }, null, 8 /* PROPS */, _hoisted_1),
              _createElementVNode("span", null, _toDisplayString($props.targetNode.data.allowed), 1 /* TEXT */)
            ], 4 /* STYLE */)
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}