import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(['sidebar-resizer', $setup.sidebar_resizing ? 'resizing' : '']),
      onMousedown: $setup.start_resize
    }, null, 34 /* CLASS, HYDRATE_EVENTS */),
    _createElementVNode("div", {
      style: _normalizeStyle({ width: `${$setup.sidebar_width}px` })
    }, [
      _createVNode($setup["Properties"])
    ], 4 /* STYLE */)
  ], 64 /* STABLE_FRAGMENT */))
}