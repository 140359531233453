import { createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "field-controls"
}
const _hoisted_2 = {
  key: 1,
  class: "control-label label"
}
const _hoisted_3 = ["value", "disabled"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["control", { editable: $setup.slots.label }])
  }, [
    _createCommentVNode(" label "),
    ($setup.slots.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "label"),
          _renderSlot(_ctx.$slots, "actions")
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($props.df.label), 1 /* TEXT */)),
    _createCommentVNode(" textarea input "),
    ($setup.slots.label)
      ? (_openBlock(), _createElementBlock("textarea", {
          key: 2,
          style: _normalizeStyle({ height: $setup.height, maxHeight: $props.df.max_height ?? '' }),
          class: "form-control",
          type: "text",
          readonly: ""
        }, null, 4 /* STYLE */))
      : (_openBlock(), _createElementBlock("textarea", {
          key: 3,
          style: _normalizeStyle({ height: $setup.height, maxHeight: $props.df.max_height ?? '' }),
          class: "form-control",
          type: "text",
          value: $props.value,
          disabled: $props.read_only || $props.df.read_only,
          onInput: _cache[0] || (_cache[0] = event => _ctx.$emit('update:modelValue', event.target.value))
        }, null, 44 /* STYLE, PROPS, HYDRATE_EVENTS */, _hoisted_3)),
    _createCommentVNode(" description "),
    ($props.df.description)
      ? (_openBlock(), _createElementBlock("div", {
          key: 4,
          class: "mt-2 description",
          innerHTML: $props.df.description
        }, null, 8 /* PROPS */, _hoisted_4))
      : _createCommentVNode("v-if", true)
  ], 2 /* CLASS */))
}